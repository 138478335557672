import { render, staticRenderFns } from "./Bug.vue?vue&type=template&id=2adc250d&"
import script from "./Bug.vue?vue&type=script&lang=js&"
export * from "./Bug.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports