

<template>
<div class="help-circle">
  <svg v-if="clicked" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle-fill p-0" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5c1.397 0 2.673.73 2.673 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.355H7.117l-.007-.463c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.901 0-1.358.603-1.358 1.384zm1.251 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z" />
  </svg>
  <svg v-if="!clicked" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle " fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    <path
      d="M5.25 6.033h1.32c0-.781.458-1.384 1.36-1.384.685 0 1.313.343 1.313 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.007.463h1.307v-.355c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.326 0-2.786.647-2.754 2.533zm1.562 5.516c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
  </svg>
</div>
</template>

<script >
export default {
  name: 'HelpCircle',
  props: ['clicked'],
}
</script>

<style scoped>
.help-circle:hover {
  color: grey;
  /* background: grey; */
  cursor: pointer;
}

svg {
  vertical-align: top !important;

}

.help-circle {
  color: var(--ff-blue-b6);
  padding: 0px;
  height: 10px;
  font-size: 22px;
  position: relative;
}
</style>
